import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
  DialogContent,
  TextField,
  Dialog,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDeleteThemeMutation, useLazyGetAllThemeQuery, useUpdateThemeByIdMutation } from "../../services/themes";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Permissions } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import Loader from "../../constants/Loader";

const ManageThemes = () => {

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [getThemes, { isLoading }] = useLazyGetAllThemeQuery();
  const [updateStatus] = useUpdateThemeByIdMutation();
  const UserData = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [themeData, setThemeData] = useState<any>();



  const userPermissions = UserData?.permissions?.[0]?.permission?.length
    ? (UserData?.permissions?.[0]?.permission)
    : [];
  console.log("TEST2", UserData?.permissions);

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  console.log("TEST", hidePermission)
  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Themes"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  //update user status api
  const updateThemeStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateStatus({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Theme status updated successfully");
        await getAllThemes();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating Theme status");
    }
  };

  //get all user api
  const getAllThemes = async () => {
    try {
      const response = await getThemes({
        page: page,
        search: debouncedSearchTerm.trim(),
        size: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setThemeData(response?.data || [])
      } else {
        setThemeData([]);
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };



  //delete user api
  // const handleDelete = async (id: string) => {
  //   try {
  //     const res = await deleteTheme({ id }).unwrap();
  //     if (res?.statusCode === 200) {
  //       showToast("Theme deleted successfully");
  //       await getAllThemes();
  //     }
  //   } catch (error: any) {
  //     console.log(error, "errror");
  //     showError(error?.message || "");
  //   }
  // };


  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    getAllThemes();
  }, [page, debouncedSearchTerm, searchTerm])

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Themes</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">

            <Box className="cards_header">
              {/* <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }
                }
              /> */}
              <Box className="cards_header_right">
                {/* {hidePermission?.isAdd || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-themes/add")}
                  >
                    Add Theme
                  </Button>
                ) : null} */}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Background Image</TableCell>
                    <TableCell>Theme Colour</TableCell>
                    <TableCell>Font size</TableCell>
                    <TableCell>Font weight</TableCell>
                    <TableCell>Date/time</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  <TableRow>
                    <TableCell align="center">1
                    </TableCell>
                    <TableCell align="center">
                      <figure className="bnr_img">
                        <img
                          src={themeData?.image ? (themeData?.image) : ("/static/images/download.png")}
                          alt=""
                        />
                      </figure>
                    </TableCell>
                    <TableCell >{themeData?.themeColor || "-"}</TableCell>
                    <TableCell >{themeData?.fontSize || "-"}</TableCell>
                    <TableCell >{themeData?.fontWeight || "-"}</TableCell>
                    <TableCell >{moment(themeData?.updatedAt).format("DD/MM/YYYY-HH:MM")}</TableCell>
                    {/* <TableCell >
                      <Switch
                        {...label}
                        size="small"
                        checked={!themeData?.isBlocked}
                        onChange={() =>
                          updateThemeStatus(themeData?._id, !themeData?.isBlocked)
                        }
                      />
                    </TableCell> */}
                    <TableCell >
                      <Box className="table_actions">
                        {(hidePermission?.isAdd || UserData?.role === 1) ? (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-themes/edit`, { state: themeData })
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageThemes;
