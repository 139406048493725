import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, UserAdded } from "../types/General";



type CommonResponseType = {
    statusCode: number;
    message: string;
};


export const ManageUserApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        PostUser: builder.mutation<
            CommonResponseType & { data: UserAdded },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.user,
                method: "POST",
                body,
            }),
        }),

        getAllUsers: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.user}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        getAllUserswithoutPage: builder.query<
            CommonResponseType & { data: any },
            {}
        >({
            query: () => ({
                url: `${END_POINTS.user}`,
                method: "GET",
            }),
        }),

        getUserById: builder.query<
            CommonResponseType & { data: UserAdded },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "GET",
            })
        }),

        updateUserById: builder.mutation<
            CommonResponseType & { data: UserAdded },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteUser: builder.mutation<
            CommonResponseType & { data: UserAdded },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "DELETE"
            })
        }),

        getUserCSV: builder.query<CommonResponseType & { data: any }, {

        }>({
            query: () => ({
                url: `${END_POINTS.userExports}`,
                method: "GET",
            }),
        }),

        uploadCsv: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.userUpload,
                method: "POST",
                body,
            }),
        }),



    })
})

export const {
    usePostUserMutation,
    useLazyGetAllUsersQuery,
    useLazyGetAllUserswithoutPageQuery,
    useLazyGetUserByIdQuery,
    useUpdateUserByIdMutation,
    useDeleteUserMutation,
    useLazyGetUserCSVQuery,
    useUploadCsvMutation,
} = ManageUserApi;