
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { error } from "console";
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import PhoneInput from "react-phone-input-2";
import React from "react";
import { ColorLens } from "@mui/icons-material";
import { showError, showToast } from "../../constants/toast";
import { useDeleteIconMutation, useLazyGetAllIconsQuery, useLazyGetThemeByIdQuery, usePostIconMutation, usePostThemeMutation, useUpdateThemeByIdMutation } from "../../services/themes";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { WarnModal } from "../../components";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  isBlocked: boolean | undefined;
  role: any;
  _id: string;
}
const AddTheme = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [showColorPicker1, setShowColorPicker1] = useState(false);
  const [showColorPicker2, setShowColorPicker2] = useState(false);
  const [fontHEX, setFontHEX] = useState<any>('');
  const [themeHEX, setThemeHEX] = useState<any>('');
  const [image, setImage] = useState<string>("");
  const [image2, setImage2] = useState<string>("");
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [addTheme] = usePostThemeMutation();
  const [editTheme] = useUpdateThemeByIdMutation();
  const [getThemeById] = useLazyGetThemeByIdQuery();
  const [addIcon] = usePostIconMutation();
  const [value, setValue] = useState<number>(0);
  const [deleteIcons] = useDeleteIconMutation();
  const [theme, setTheme] = useState<any>(state);

  console.log(theme, "theme");


  const handleColorButtonClick1 = () => {
    setShowColorPicker1(!showColorPicker1);
  };

  const handleColorButtonClick2 = () => {
    setShowColorPicker2(!showColorPicker2);
  };


  console.log(image, "image");
  console.log(fontHEX, "fontHEX");
  console.log(themeHEX, "themeHEX");



  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      image: theme?.image || "",
      fontSize: theme?.fontSize || "",
      fontColor: theme?.fontColor || "",
      themeColor: theme?.themeColor || "",
      fontWeight: theme?.fontWeight || "",
    },
    validationSchema: Yup.object({
      fontSize: Yup.string()
        .required("This field is required")
        .max(3, "Maximum 3 characters are allowed")
        .min(1, "Minimum 1 character is required"),
      fontWeight: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed")
        .min(1, "Minimum 1 character is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const Body: {
        fontSize?: string;
        fontWeight?: string;
        fontColor?: string;
        image?: string;
        themeColor?: string;
      } = {};

      if (formik.values.fontSize !== theme?.fontSize) {
        Body['fontSize'] = formik.values.fontSize;
      }
      if (formik.values.fontWeight !== theme?.fontWeight) {
        Body['fontWeight'] = formik.values.fontWeight;
      }
      if (fontHEX !== theme?.fontColor) {
        Body['fontColor'] = fontHEX;
      }
      if (themeHEX !== theme?.themeColor) {
        Body['themeColor'] = themeHEX;
      }
      if (image !== theme?.image) {
        Body['image'] = image;
      }

      if (!image) {
        showError("Image is required");
        formik.setSubmitting(false);
        return;
      }

      if (!fontHEX) {
        showError("Font color is required");
        formik.setSubmitting(false);
        return;
      }

      if (!themeHEX) {
        showError("Theme color is required");
        formik.setSubmitting(false);
        return;
      }

      // if (id) {
      //   try {
      //     let encryptedBody = generateEncryptedKeyBody(Body) as CommonBody;
      //     const response = await editTheme({ id: id, body: encryptedBody }).unwrap();
      //     if (response?.statusCode === 200) {
      //       showToast(response?.message || "");
      //       navigate("/manage-themes");
      //     } else {
      //       showError(response?.message || "");
      //     }
      //   } catch (error: any) {
      //     showError(error?.data?.message || "");
      //     console.log(error);
      //   }
      // } else {
      try {
        let encryptedBody = generateEncryptedKeyBody(Body) as CommonBody;
        const response = await addTheme(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Theme Updated Successfully");
          formik.resetForm();
          navigate("/manage-themes");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      } finally {
        formik.setSubmitting(false);
      }
      // }
    },
  });



  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };


  console.log(formik.isValid, "validdd")

  // const getThemeDetails = async () => {
  //   try {
  //     const response = await getThemeById({ id }).unwrap();
  //     if (response?.statusCode === 200) {
  //       setTheme(response?.data);
  //       setImage(response?.data?.image);
  //     }
  //   } catch (error: any) {
  //     console.log(error, "errror");
  //     showError(error?.data?.message || "");
  //   }
  // };




  useEffect(() => {
      setImage(theme?.image);
      setFontHEX(theme?.fontColor)
      setThemeHEX(theme?.themeColor)
  }, []);


  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Edit Theme</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subadmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">

            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Background Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2" style={{ height: "420px", width: "25%" }}>
                        <CardMedia
                          component="img"
                          image={image}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr" style={{ height: "420px", width: "25%" }}>
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button
                            component="span"
                            className="upload_image_btn"
                          >
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/download.png"
                              }
                              alt=""
                            />
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="custom_label">
                      Font Size
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="fontSize"
                      variant="outlined"
                      fullWidth
                      placeholder="Font Size"
                      value={formik.values.fontSize}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      className="text_field"
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.fontSize && typeof formik.errors.fontSize === 'string'
                          ? formik.errors.fontSize
                          : undefined
                      }
                      inputProps={{ maxLength: 30 }}
                    />

                  </Grid>


                  {/* <Grid item xs={5}>
                    <Typography className="custom_label">
                      Tab font colour
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="fontColor"
                      variant="outlined"
                      fullWidth
                      placeholder="Font colour"
                      value={fontHEX}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleColorButtonClick1}>
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showColorPicker1 && (
                      <ColorPicker
                        value={fontHEX}
                        inputId="cp-hex" format="hex"
                        onChange={(e: ColorPickerChangeEvent) => setFontHEX(e.value)}
                        inline
                      />
                    )}
                  </Grid> */}

                  <Grid item xs={5}>
                    <Typography className="custom_label">
                      Tab font colour
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="fontColor"
                      variant="outlined"
                      fullWidth
                      placeholder="Font colour"
                      value={fontHEX}
                      onChange={(e) => setFontHEX(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="click to open/close">
                              <IconButton onClick={handleColorButtonClick1}>
                                <ColorLens />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showColorPicker1 && (
                      <ColorPicker
                        value={fontHEX}
                        inputId="cp-hex"
                        format="hex"
                        onChange={(e: ColorPickerChangeEvent) => setFontHEX(e.value)}
                        inline
                      />
                    )}
                  </Grid>


                  <Grid item xs={5}>
                    <Typography className="custom_label">
                      Font weight
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="fontWeight"
                      variant="outlined"
                      fullWidth
                      placeholder="Font weight"
                      value={formik.values.fontWeight}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      className="text_field"
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.fontWeight && typeof formik.errors.fontWeight === 'string'
                          ? formik.errors.fontWeight
                          : undefined
                      }
                      inputProps={{ maxLength: 30 }}
                    />

                  </Grid>

                  <Grid item xs={5}>
                    <Typography className="custom_label">
                      Tab background colour
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="themeColor"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setThemeHEX(e.target.value)}
                      placeholder="Font colour"
                      value={themeHEX}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="click to open/close">
                              <IconButton onClick={handleColorButtonClick2}>
                                <ColorLens />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showColorPicker2 && (
                      <ColorPicker
                        value={themeHEX}
                        inputId="cp-hex" format="hex"
                        onChange={(e: ColorPickerChangeEvent) => setThemeHEX(e.value)}
                        inline
                      />
                    )}
                  </Grid>
                  <br />
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>

        </div>
      </MainContainer>
    </>
  );
};

export default AddTheme;
