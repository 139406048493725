import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { useLazyGetAdminNotificationQuery } from "../../services/notification";
import { showError } from "../../constants/toast";

const RecievedNotifications = () => {
  const [receivedNot] = useLazyGetAdminNotificationQuery();
  const [notData, setNotData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  let totalPages = Math.ceil(totalCount / 10);

  const fetchNotification = async () => {
    try {
      const res = await receivedNot({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setNotData(res?.data?.data)
        setTotalCount(res?.data?.count || 0)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  useEffect(() => {
    fetchNotification();
  }, [page, debouncedSearchTerm, searchTerm]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }
              }
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center"> 1</TableCell>
                  <TableCell>CustomerName</TableCell>
                  <TableCell>CustomerName</TableCell>
                  <TableCell>CustomerName</TableCell>
                  <TableCell>message</TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default RecievedNotifications;
