import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform" element={<UsersForm />} />
      <Route path="/manageusers/edit-user/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route path="/settings" element={<Pages.Settings />} />
      <Route path="/manage-banner" element={<Pages.ManageBanner />} />
      <Route path="/manage-banner/add" element={<Pages.AddBanner />} />
      <Route path="/manage-banner/edit/:id" element={<Pages.AddBanner />} />
      <Route
        path="/manage-themes"
        element={<Pages.ManageThemes />}
      />
      <Route
        path="/manage-themes/add"
        element={<Pages.AddTheme />}
      />
      <Route
        path="/manage-themes/edit"
        element={<Pages.AddTheme />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-icons" element={<Pages.ManageIcons />} />
      <Route path="/manage-icons/add" element={<Pages.AddIcons />} />
      <Route path="/manage-icons/edit/:id" element={<Pages.AddIcons />} />

      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />
    
    </Routes>
  );
};

export default Routing;
