export const API_URL = "https://goldvalleyapi.appgrowthcompany.com/api/v1/admin/";

export const END_POINTS = {
    //auth
    signUp: "signup",
    login: "login",
    forgotPassword: "forgotPassword",
    getProfile: "profile",
    updateProfile: "updateProfile",
    mediaUpload: "upload",
    verifyOtp: "verifyOtp",
    setPassword: "setPassword",
    changePassword: "changePassword",
    logout: "logout",

    //banner
    banner:"banner",
    user:"user",
    theme:"theme",
    themeIcon:"themeIcon",
    dashboard:"dashboard",
    newUsersGraph:"newUsersGraph",
    totalUserGraph:"totalUsersGraph",
    notification:"notification",
    adminNotification:"adminNotification",
    cms:"cms",
    userExports:"userExports",
    userUpload:"userUpload",
    role:"role",
    subAdmin:"subAdmin",
    subAdminsExports:"subAdminsExports",
    




}


