import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardQuery, useLazyGetTotalUserGraphQuery, useLazyGetUserGraphQuery } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const DashBoard = () => {
  const [dashData, setDashData] = useState<any>();
  const [getDashboard] = useLazyGetDashboardQuery();
  const [userGraphData, setUserGraphData] = useState<number[]>([]);
  const [userGraphName, setUserGraphName] = useState<string[]>([]);
  const [newUserData, setNewUserData] = useState<number[]>([]);
  const [newUserName, setNewUserName] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>("monthly");
  const [userGraph] = useLazyGetTotalUserGraphQuery();
  const [newUser] = useLazyGetUserGraphQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);



  const getUserGraph = async () => {
    setIsLoading(true);
    try {
      const response = await userGraph({ type: selectedTime }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = response?.data.map((item: any) => item.name) || [];
        const values = response?.data.map((item: any) => typeof item.value === 'number' ? item.value : 0);
        setUserGraphName(userKeys)
        setUserGraphData(values)
        setIsLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      setIsLoading(false)
    }
  };

  const getNewUserGraph = async () => {
    setIsLoading(true)
    try {
      const response = await newUser({ type: selectedTime }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = response?.data.map((item: any) => item.name) || [];
        const values = response?.data.map((item: any) => typeof item.value === 'number' ? item.value : 0);
        setNewUserName(userKeys)
        setNewUserData(values)
        setIsLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      setIsLoading(false)
    }
  };

  const fetchDashboard = async () => {
    setIsLoading(true)
    try {
      const res = await getDashboard({
      }).unwrap();
      if (res?.statusCode === 200) {
        setDashData(res?.data);
        setIsLoading(false)
      }
    } catch (error) {

      setIsLoading(false)
      console.log(error);

    }
  }

  const graphUserData = {
    labels: userGraphName,
    datasets: [
      {
        label: "Total Users",
        data: userGraphData,
        borderColor: "rgb(204, 193, 35)",
        backgroundColor: "rgb(204, 193, 35)",
      },
    ],
  };

  const graphNewUserData = {
    labels: newUserName,
    datasets: [
      {
        label: "New users",
        data: newUserData,
        borderColor: "rgb(204, 193, 35)",
        backgroundColor: "rgb(204, 193, 35)",
      },
    ],
  };

  useEffect(() => {
    fetchDashboard();
  }, [])

  useEffect(() => {
    getNewUserGraph();
    getUserGraph();
  }, [selectedTime])



  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">{dashData?.totalUser || "0"} </h4>
            </div>
          </Item>
        </Grid>

        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>New Users</h3>
              <h4 className="mn_hdng">{dashData?.newUser || "0"}</h4>
            </div>
          </Item>
        </Grid>

      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Users</h2>
            <LineChart data={graphUserData} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">New Users</h2>
            <LineChart data={graphNewUserData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
