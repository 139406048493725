import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useAddCmsMutation, useLazyGetCmsQuery, usePutCmsMutation } from "../../services/cms";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [legal, setLegal] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [putCms] = usePutCmsMutation();
  const [getCms] = useLazyGetCmsQuery();
  const [addCms] = useAddCmsMutation();


  const [value, setValue] = React.useState(0);

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      countryCode: "",
      privacyPolicy: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        privacyPolicy: privacyPolicy,
      };
      console.log(body, "body..............");

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await putCms(encryptedBody).unwrap();
        if (response.statusCode === 200) {
          showToast("Cms updated succesfully")
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    }
  })

  const handleChangePhone = (phone: any, country: any) => {
    console.log(country, "country");
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const getAllCms = async () => {
    try {
      const response = await getCms({}).unwrap();
      if (response.statusCode === 200) {
        // const cmsData = response?.data;
        formik.setValues({
          email: response?.data?.email || "",
          phone: response?.data?.phone || "",
          countryCode: response?.data?.countryCode || "+91",
          privacyPolicy: response?.data?.privacyPolicy || "",
        });
        setPrivacyPolicy(response?.data?.privacyPolicy || "");
        const phone = response?.data?.countryCode
        setPhoneCode(phone || "");

      }

    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }


  useEffect(() => {
    getAllCms();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              {/* <Tab label="Terms and Conditions" {...a11yProps(2)} />
              <Tab label="Program Agreement" {...a11yProps(4)} /> */}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        (formik.touched.email && formik.errors.email) || ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      country={"in"}
                      placeholder="0 (000) 000-000"
                      inputStyle={{ width: "100%" }}
                      value={phoneCode + formik.values.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <form>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form>
                <EditText content={legal} setContent={setLegal} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel> */}
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
