import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isString, useFormik } from "formik";
import { isValidInput } from "../../utils/validations";
import { useDeleteIconMutation, useLazyGetAllIconsQuery } from "../../services/themes";
import { showError, showToast } from "../../constants/toast";
import { Pagination, WarnModal } from "../../components";
import moment from "moment";
import Loader from "../../constants/Loader";
import { Permissions } from "../../types/General";
import useAuth from "../../hooks/useAuth";


const ManageIcons = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [getIcons, { isLoading }] = useLazyGetAllIconsQuery();
  const [page, setPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState(0);
  const [deleteIcon] = useDeleteIconMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const UserData = useAuth();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [icons, setIcons] = useState<any[]>([]);
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Icons"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  const handleDeleteIcons = async (id: string) => {
    try {
      const response = await deleteIcon({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        fetchIcons();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const fetchIcons = async () => {
    try {
      const res = await getIcons({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setIcons(res?.data?.data)
        setTotalCount(res?.data?.count || 0)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };


  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    fetchIcons();
  }, [debouncedSearchTerm, page, searchTerm])

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Icons</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">

            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }
                }
              />
              <Box className="cards_header_right">
                {hidePermission?.isAdd || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-icons/add")}
                  >
                    Add Icon
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Icon</TableCell>
                    <TableCell align="center">Tab Title</TableCell>
                    <TableCell align="center">Date created</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {icons?.length ? (
                  icons.map((row, i) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">
                          <figure className="bnr_img">
                            <img
                              src={row?.icon ? row.icon : "/static/images/download.png"}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell align="center">{row?.title}</TableCell>
                        <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY / HH:MM:SS")}</TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isDelete || UserData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setSelectedId(row?._id)
                                    setOpen(true)
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                  )
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Data Found
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

          </Box>
        </Card>
      </div>
      <Pagination
        module={icons}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="icon"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteIcons(selectedId)}
      />

    </MainContainer>
  );
};

export default ManageIcons;
