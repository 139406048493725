import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        putCms: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.cms,
                method: "PUT",
                body,
            }),
        }),
        addCms: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.cms,
                method: "POST",
                body,
            }),
        }),
        getCms: builder.query<CommonResponseType & { data: any }, {}>({
            query: () => ({
                url: END_POINTS.cms,
                method: "GET",
            }),
        }),

    })
})

export const {
    usePutCmsMutation,
    useAddCmsMutation,
    useLazyGetCmsQuery,
} = cmsApi;
