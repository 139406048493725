import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    size?: number;
    query?: string;
};


export const notificationApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        addNotification: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody>({
                query: (body) => ({
                    url: END_POINTS.notification,
                    method: "POST",
                    body
                })
            }),

        getNotification: builder.query<
            CommonResponseType & { data: any },
            GetTokenParams
        >({
            query: ({ query, page, size }) => ({
                url: `${END_POINTS.notification}?search=${query}&page=${page}&size=${size}`,
                method: "GET",
            })
        }),


        deleteNotification: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.notification}/${id}`,
                method: "DELETE",
            }),
        }),

        updateNotById: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.notification}/${id}`,
                method: "PUT",
                body
            })
        }),


        getAdminNotification: builder.query<
            CommonResponseType & { data: any },
            GetTokenParams
        >({
            query: ({ query, page, size }) => ({
                url: `${END_POINTS.adminNotification}?search=${query}&page=${page}&size=${size}`,
                method: "GET",
            })
        }),

        deleteAdminNotification: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.adminNotification}/${id}`,
                method: "DELETE",
            }),
        }),

        // deleteAllAdminNotification: builder.mutation<
        //     CommonResponseType & { data: responce },
        //     { clearAll: boolean }
        // >({
        //     query: ({ clearAll }) => ({
        //         url: `${END_POINTS.adminNotification}?clearAll=${clearAll}`,
        //         method: "DELETE",
        //     }),
        // }),
    })
})

export const {
    useAddNotificationMutation,
    useLazyGetNotificationQuery,
    useDeleteNotificationMutation,
    useUpdateNotByIdMutation,
    useLazyGetAdminNotificationQuery,
    useDeleteAdminNotificationMutation,

} = notificationApi;