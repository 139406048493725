import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, Permissions } from "../../types/General";
import { useDeleteSubAdminMutation, useLazyGetAllSubAdminQuery, useUpdateSubAdminMutation } from "../../services/subAdmin";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import { Pagination, WarnModal } from "../../components";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [subAdmins, setSubAdmins] = useState<any>([]);


  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.[0]?.permission?.length
    ? (UserData?.permissions?.[0]?.permission)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Sub-Admin"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])


  const [getSubAdmins] = useLazyGetAllSubAdminQuery();
  const [updateAdminStatus]: any = useUpdateSubAdminMutation();
  const [deleteById] = useDeleteSubAdminMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteById({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getSubAdminsList();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };


  const getSubAdminsList = async () => {
    try {
      const response = await getSubAdmins({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("SUB", response);
        setSubAdmins(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubAdmins([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  const handleStatusChange = async (index: number) => {
    try {
      const body = {
        isBlocked: !subAdmins[index]?.isBlocked
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateAdminStatus({ id: `${subAdmins[index]._id}`, body: encryptedData }).unwrap();
      showToast(response?.message || "Sub-admin status Updated Successfully");
      setSubAdmins((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };
  useEffect(() => {
    getSubAdminsList();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }
              }
            />
            {hidePermission?.isAdd || UserData?.role === 1 ? (
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-subAdmin/add")}
                >
                  Add Sub-Admin
                </Button>
              </Box>
            ) : null}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Date/time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {subAdmins?.length ? (
                subAdmins.map((row: any, i: any) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell >{row?.firstName || "-"}</TableCell>
                      <TableCell >{row?.permissions?.roleName || "-"}</TableCell>
                      <TableCell >{row?.email || "-"}</TableCell>
                      <TableCell >{row?.countryCode
                        ? (row?.countryCode.includes("+") ? "" : "+") +
                        row?.countryCode
                        : null}{" "}
                        {row?.phone || "-"}</TableCell>
                      <TableCell >{moment(row?.createdAt).format("DD-MM-YYYY / HH:MM")}</TableCell>
                      <TableCell>
                        <Switch {...label}
                          checked={!row.isBlocked}
                          size="small"
                          onChange={() => handleStatusChange(i)} />
                      </TableCell>
                      <TableCell >
                        <Box className="table_actions">
                          {hidePermission?.isAdd || UserData?.role === 1 ? (
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => navigate("/manage-subAdmin/edit/" + row?._id)}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {hidePermission?.isDelete || UserData?.role === 1 ? (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedId(row?._id)
                                  setOpen(true)
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                )
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={subAdmins}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="Sub-admin"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
