import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { usePostLoginMutation } from "../../services/auth";
import { useAppDispatch } from "../../hooks/store";
import { getFromStorage, removeFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { setCredentials } from "../../reducers/authSlice";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: "Manage Themes",
      icon: <SubscriptionsIcon />,
      key: "3",
      selected: "/manage-themes",
    },
    {
      label: "Manage Ads",
      icon: <ViewCarouselIcon />,
      key: "4",
      selected: "/manage-banner",
    },
    {
      label: "Notifications Management",
      icon: <NotificationsActiveIcon />,
      key: "5",
      selected: "/manage-notifications",
    },
    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "6",
      selected: "/manage-subAdmin",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "7",
      selected: "/cms",
    },

  ];



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      console.log("on submit");

      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      console.log(fcmtoken, "fcmtoken");

      let data = {
        key: formik.values.email,
        password: formik.values.password,
        deviceType: "WEB",
        deviceToken: fcmtoken || "1234"
      };
      console.log(data, "data..");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await loginMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Logged in Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }


          if (response?.data?.role === 2) {
            const permissions = response?.data?.permissions;
            if (response?.data?.role === 2 && permissions?.length > 0) {
              const firstPermissionLabel = permissions[0]?.label;
              const redirectPath = items.find(
                (item) => item.label === firstPermissionLabel
              )?.selected;

              if (redirectPath) {
                navigate(redirectPath);
              } else {
                console.error("Invalid permission label for redirection");
              }
            }
          } else {
            navigate("/dashboard");
          }
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1c6975" }}>
            <Typography variant="h5">Log in to Gold Valley Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                className="text_field"
                name="email"
                type="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                className="text_field"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
