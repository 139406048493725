import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useDeleteNotificationMutation, useLazyGetNotificationQuery, useUpdateNotByIdMutation } from "../../services/notification";
import { showError, showToast } from "../../constants/toast";
import { Pagination, WarnModal } from "../../components";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Permissions } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [getAllNotification, { isLoading }] = useLazyGetNotificationQuery();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [notData, setNotData] = useState<any[]>([]);
  const UserData = useAuth();
  const [selectedId, setSelectedId] = useState<string>("")
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [updateNotification] = useUpdateNotByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);
  const label = { inputProps: { "aria-label": "Switch demo" } };


  const userPermissions = UserData?.permissions?.[0]?.permission?.length
    ? (UserData?.permissions?.[0]?.permission)
    : [];
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Notification Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  const handleDelete = async (id: string) => {
    try {
      const response = await deleteNotification({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        fetchNotification();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const updateNotStatus = async (id: string, status: boolean) => {
  //   const data = {
  //     isBlocked: status,
  //   };
  //   try {
  //     let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
  //     const res = await updateNotification({ id, body: encryptedBody }).unwrap();
  //     if (res?.statusCode === 200) {
  //       showToast("Notification status updated successfully");
  //       await fetchNotification();
  //     }
  //   } catch (error: any) {
  //     console.log(error, "error");
  //     showError(error?.message || "Error updating notification status");
  //   }
  // };
  const truncateText = (text: string | undefined, wordLimit: number) => {
    if (!text) {
      return "";
    }
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };



  const fetchNotification = async () => {
    try {
      const res = await getAllNotification({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setNotData(res?.data?.data)
        setTotalCount(res?.data?.count || 0)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  useEffect(() => {
    checkPermission();
  }, [UserData])


  useEffect(() => {
    fetchNotification();
  }, [page, debouncedSearchTerm, searchTerm]);

  console.log(notData, "notData");

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }
              }
            />
            <Box className="cards_header_right">
              {hidePermission?.isAdd || UserData?.role === 1 ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-notifications/add")}
                >
                  Add Notification
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Message</TableCell>
                  <TableCell align="center">Date/time</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              {notData?.length ? (
                notData.map((row, i) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell align="center">{row?.title}</TableCell>
                      <TableCell align="center">{truncateText(row?.messages, 2)}</TableCell>
                      <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY / HH:MM:SS")}</TableCell>
                      <TableCell align="center">
                        <Box className="table_actions">
                          {hidePermission?.isDelete || UserData?.role === 1 ? (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedId(row?._id)
                                  setOpen(true)
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                )
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={notData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="notification"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
      />
    </MainContainer>
  );
};

export default ManageNotifications;
